var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated)?_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",style:({
          background: _vm.$store.state.sellTheBill.showBtnPrint
            ? 'rgb(234 234 234)'
            : '#fff',
        }),attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay)?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:{
              'container-fluid': _vm.contentFluid,
              container: !_vm.contentFluid,
            }},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])],1)],1)],1),_c('KTScrollTop')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }