<template>
  <div
      class="subheader py-2 py-lg-4"
      v-bind:class="subheaderClasses"
      id="kt_subheader"
      v-if="$store.state.sellTheBill.showBtnPrint"
  >
    <div
        class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--        <h5 class="text-dark font-weight-bold my-2 mr-5" style="font-size: 18px">-->
        <!--          {{ title }}-->
        <!--        </h5>-->
        <template v-for="(breadcrumb, i) in breadcrumbs">
          <li class="breadcrumb-item my-2 mr-3" style="padding-left: 0;padding-top: 6px; list-style: none"
              :key="`${i}-${breadcrumb.id}`">
            <h5 class="text-dark font-weight-bold" style="font-size: 18px" v-if="breadcrumb.route">
              {{ breadcrumb.title }}
            </h5>
            <h5 class="text-dark font-weight-bold " :key="i" v-if="!breadcrumb.route">
              {{ breadcrumb.title }}
            </h5>
          </li>
        </template>
        <div class="addRoom" v-if="title === 'Khách hàng'" @click="redirectAddStaff(true)">
          <i class="flaticon2-plus "></i>
        </div>

      </div>

    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.btnSDBP {
  width: 108px;
  padding: 0;
  height: 37px;
}
</style>

<script>
import {mapGetters} from "vuex";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from "moment";
import {
  FETCHAPI,
  LINK_BOOKING_GROUP,

} from "@/core/services/store/detailsReservetion/detailsReservetion.module";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    VueCtkDateTimePicker,
  },
  data() {
    return {
      dateNow: '',
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", 'statusAddRoom']),

    statusAdd() {
      return this.statusAddRoom;
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  },
  watch: {
    dateNow() {
      this.$store.commit('SET_DATE_DIAGRAM',  this.dateNow);
    },
    "$store.state.diagram.dateDiagram"() {
      this.dateNow = this.$store.state.diagram.dateDiagram

    }
  },
  methods: {

    linkBookingGroup(){
      let obj = {
        key: Math.random(),
        val: this.$store.state.detailsReservetion.detailData.parent_booking_id,
      }
      this.$store.commit(LINK_BOOKING_GROUP, obj);


    },
    redirectAddRoom() {
      this.$router.push({path: '/diagram/addRoom'})
    },
    redirectAddAgent() {
      console.log(121)
      this.$router.push({name: 'addAgent'})
    },
    redirectAddStaff(status) {
      this.$store.commit('SET_STATUS_SHOW_MODAL_STAFF', status);
    },
    redirectAddNewRoom(status) {
      if(this.$store.state.diagram.showBookRoom){
        this.$store.commit('SHOW_ADD_ROOM', status);
      }
    },
    changeType(status) {
      this.$store.commit('SET_STATUS_ADD_ROOM', status);
    },
    //SHOW_ADD_ROOM
    redirectAddVisitors() {
      this.$router.push({name: 'addVisitors'})
    }
  }
};
</script>
