<template>
  <!--  &lt;!&ndash; begin:: Header Topbar &ndash;&gt;-->
  <!--      <div v-clickOutside="closeEvent">-->
  <!--        <div class="topbar position-relative w-100" >-->
  <!--          <button class="position-relative"-->
  <!--                  @click="showInfo  = !showInfo">-->
  <!--              <span class="header-ks position-absolute control-header-ks">Khách sạn Sapa  Luxury</span>-->
  <!--              <v-avatar color="teal" size="48">-->
  <!--                <img-->
  <!--                    src="../../../assets/img/81902066_851556878611675_461635664933289984_n.jpg"-->
  <!--                >-->
  <!--              </v-avatar>-->
  <!--          </button>-->
  <!--          <div v-if="showInfo" class="show-info position-absolute py-2">-->
  <!--            <div class="w-100 bg-white mr-10">-->
  <!--              <div class="d-flex cursor-pointer" style="margin: 7px 0" @click="toProfile">-->
  <!--                <i class="flaticon-user ml-4 mr-4" style="color: black!important;"></i>-->
  <!--                <p class="header-ks text-left">Thông tin tài khoản</p>-->
  <!--              </div >-->
  <!--              <div class="d-flex " style="color: red!important;cursor: pointer"  @click="onLogout" >-->
  <!--                <i class="flaticon-logout ml-4 mr-4" style="color: red!important;"></i>-->
  <!--                <p class="header-ks text-left">Đăng xuất</p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--  &lt;!&ndash; end:: Header Topbar &ndash;&gt;-->
  <div style="display: flex;">
<!--    <div class="balance destop" v-if="userInfo.balance">Số dư: <span>{{getFormatPrice(userInfo.balance)}} đ</span></div>-->
    <span class="header-ks nameUser destop">{{ userInfo.name }}</span>

    <div class="topbar boxUser">

      <!--begin: Language bar -->
      <div class="topbar-item">
        <b-dropdown
            size="sm"
            variant="link"
            toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
            no-caret
            right
            no-flip
        >
          <template v-slot:button-content>
            <span class="header-ks position-absolute control-header-ks nameUser mobie">{{ userInfo.name }}</span>
            <v-avatar color="teal" size="48">
              <img
                  src="../../../assets/img/default_logo.png"
              >
            </v-avatar>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-250px">
            <ul class="navi navi-hover ">
<!--              <li class="navi-item">-->
<!--                <a-->
<!--                    href="#"-->
<!--                    class="navi-link"-->
<!--                    @click="toProfile"-->
<!--                >-->
<!--                  <span class="symbol symbol-20 mr-3">-->
<!--                       <i class="la la-user-circle-o ml-4 mr-4"></i>-->
<!--                  </span>-->
<!--                  <span class="navi-text">Thông tin tài khoản</span>-->
<!--                </a>-->
<!--              </li>-->
              <li class="navi-item">
                <a
                    href="#"
                    class="navi-link"
                    @click="onLogout"
                >
                  <span class="symbol symbol-20 mr-3">
                       <i class="la la-sign-out-alt ml-4 mr-4" style="color: red!important;"></i>
                  </span>
                  <span class="navi-text">Đăng xuất</span>
                </a>
              </li>
            </ul>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <!--end: Language bar -->
    </div>
  </div>
</template>
<script>
// import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import {LOGOUT} from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import {EventBus} from "@/view/pages/share/event-bus";
import {formatPrice} from "@/assets/js/components/fomatValue";

export default {
  name: "KTTopbar",
  data() {
    return {
      // showInfo: false,
      userInfo: '',
    };
  },
  created() {
    this.getApiUser();
    EventBus.$on('component-a-clicked', click => {
      console.log('hahaha')
      this.getApiUser();
    });
  },
  components: {},
  methods: {
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => {
                this.$router.push({name: "login"})
                JwtService.destroyUser()
              }
          );
    },

    toProfile() {
      const path = '/profile'
      if (this.$route.path !== path) {
        this.$router.push({path: path});
      }

    },
    getApiUser() {
      ApiService.get("user/me").then((res) => {
        console.log('res.data.data', res.data.data)
        this.userInfo = res.data.data;
      })

    },
    getFormatPrice(_value) {
      return formatPrice(Number(_value))
    },
    // closeEvent() {
    //   this.showInfo = false;
    // }
  },
  computed: {
    // getLanguageFlag() {
    //   return this.onLanguageChanged();
    // }
  }
};
</script>
<style lang="scss">
.balance{
  margin-right: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #4F4F4F;
  top: 11px;
  position: relative;
  span{
    color: #9B51E0;
  }
}
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.header-ks {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
}

.control-header-ks {
  right: 60px;
  top: 15px;
}

.show-info {
  top: 55px;
  right: 0;
  background: white;
  filter: drop-shadow(1px 4px 4px rgba(0, 0, 0, 0.25));
  width: 272px;
  height: 88px;
}

.img-ks {
  height: 48px;
  width: 48px;
  left: 1129px;
  top: 7px;
  border-radius: 50%;
}

.nameUser {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #4F4F4F;
  top: 11px;
  margin-right: 12px;
  position: relative;
}

.boxUser {
  .navi-text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px !important;
    color: #4F4F4F !important;
    top: 11px;
  }

  .navi-link {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .symbol {
    i {
      font-size: 29px;
      margin: 0 !important;
      color: #4F4F4F !important;
      position: relative;
      top: 1px;
    }
  }
}
.destop{
  display: block;
}
.mobie{
  display: none;
}
@media (max-width: 991.98px) {
  .topbar {
    height: 78px;
  }
  .destop{
    display: none;
  }
  .mobie{
    display: block;
  }
  .nameUser{
    margin-right: 0;
  }
  .navi-text {
    top: 8px;
  }
  .header-mobile-fixed .header-mobile {
    height: 66px;
    min-height: 66px;
  }

}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item.menu-item-here > .menu-link > .menu-icon, .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link > .menu-icon {
    color: #9B51E0 !important;
  }
}
</style>

