<template>
  <ul class="menu-nav">
    <template >
      <router-link
          :to="'/dashboard'"
          v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-layers"></i>
            <span class="menu-text">Dashboad</span>
          </a>
        </li>
      </router-link>
      <router-link
          :to="'/managementAgent'"
          v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-users"></i>
            <span class="menu-text">Quản lý khách hàng</span>
          </a>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
import BaseService from "@/core/helper/base.service";
import _ from 'lodash';
import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTMenu",
  created() {
    setTimeout(() => {
      this.menuApi()
      // this.checkRouter()
    }, 500)
  },
  mounted() {
    // this.checkRouter()
  },
  data() {
    return {
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    menuApi() {
      console.log(typeof this.$route.path, this.$route.path)
    },
    // checkRouter() {
    //
    // }
  }
};
</script>
