export const formatPrice = price => {
    if (price === Infinity) return "-";
    try {
        const priceString = price.toString();

        if (priceString.includes(".")) {
            const number = formatPrice(Number(priceString.split(".")[0]));
            const decimal = priceString.split(".")[1] || "0";

            if (decimal === "0") {
                return number;
            }

            return `${number}.${decimal.slice(0, 2)}`;
        }

        return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } catch (error) {
        console.error(error);
        return "-";
    }
};
export const isEmptyObject = (_object) => {
    let bl = Boolean(Object.keys(_object).length)
    return !bl
}
// return Object.keys(_object).length === 0 && _object.constructor === Object;

export const isEmptyField = (_requiredField, _object) => {
    // truyền vào _requiredField là dạng object
    // vd : {name : 'msg'} => nếu thiếu trường name thì error return ra {name:'msg'}
    let error = {}
    for (const field in _requiredField) {
        if (!_object[field]) {
            error[field] = _requiredField[field]
        }
    }
    return error
    // return object  keys : invalid field, value : msg
}
export const calculationPrice = (_number, _price) => {
    //price la so tien , number la gia tri can nhan len
    let _formatPrice = _price.replace(/[^\d]+/g, "");
    return formatPrice(Number(_number) * Number(_formatPrice))
}
